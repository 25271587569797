import axios from "axios";

const instance = axios.create({
  // baseURL: "http://localhost",
  // baseURL: "http://45.183.247.162:8080",
  baseURL:"https://digitalcaf.com.co:8443",
  headers: {
    "Content-Type": "application/json"
  },
});

export default instance;